<template>
  <el-button v-bind="[item]" @click="onclilck(item.clickEvent)">{{item.label}}</el-button>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    onclilck(fun) {
      if (fun && typeof (fun) === 'function') {
        fun(this.$parent.$parent)
      }
    }
  }
}

</script>
